import Breadcrumb from "@/components/layouts/Breadcrumb/Breadcrumb";
import Header, { HeaderLeft } from "@/components/layouts/Header/Header";
import { Query } from "@/gql/graphql";
import { PRIVACY_POLICY } from "@/gql/schema";
import { useQuery } from "@apollo/client";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/privacy-policy")({
  component: PrivacyPolicy,
});

function PrivacyPolicy() {
  const data = useQuery<Query>(PRIVACY_POLICY);
  return (
    <div>
      <Header>
        <HeaderLeft>
          <img
            src="/images/logo32x32.png"
            alt="logo"
            className="h-[80px] mr-[16px]"
          />
          <Breadcrumb path="" currentPage="Privacy Policy" />
        </HeaderLeft>
      </Header>
      <div
        className="w-full p-[16px] text-[16px] leading-[24px]"
        dangerouslySetInnerHTML={{ __html: data.data?.privacyPolicy ?? "" }}
      ></div>
    </div>
  );
}
