import Icon from "@/components/icon/Icon";
import Breadcrumb from "@/components/layouts/Breadcrumb/Breadcrumb";
import Container from "@/components/layouts/Container/Container";
import Header, {
  HeaderLeft,
  HeaderRight,
} from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Badge from "@/components/ui/Badge";
import Button from "@/components/ui/Button";
import Card, { CardBody } from "@/components/ui/Card";
import Table, { TBody, Td, Th, THead, Tr } from "@/components/ui/Table";
import {
  Mutation,
  MutationSubscriptionUseCaseCancelSubscriptionArgs,
  Query,
  Subscription,
} from "@/gql/graphql";
import {
  SUBSCRIPTION_USE_CASE_ACTIVE_SUBSCRIPTION,
  SUBSCRIPTION_USE_CASE_CANCEL_SUBSCRIPTION,
  SUBSCRIPTION_USE_CASE_SUBSCRIPTION_HISTORY,
  SUBSCRIPTION_USE_CASE_UPGRADE_SUBSCRIPTION,
} from "@/gql/schema";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { useMutation, useQuery } from "@apollo/client";
import { createFileRoute, redirect } from "@tanstack/react-router";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import toast from "react-hot-toast";

export const Route = createFileRoute("/your-subscription")({
  component: YourSubscription,
  beforeLoad: ({ context, location }) => {
    if (!context.user) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

const columnHelper = createColumnHelper<Subscription>();

const columns = [
  columnHelper.accessor("planId", {
    cell: (info) => (
      <div className="text-2xl font-bold uppercase">{info.getValue()}</div>
    ),
    header: "Plan",
    footer: "Plan",
  }),
  columnHelper.accessor("expiredDate", {
    cell: (info) => (
      <div className="text-2xl font-bold">
        {dayjs(info.getValue()).format("DD MMM YYYY")}
      </div>
    ),
    header: "Expired Date",
    footer: "Expired Date",
  }),
  columnHelper.accessor("isActive", {
    cell: (info) => (
      <Badge
        variant={info.getValue() ? "solid" : "outline"}
        className="!border-transparent"
      >
        {info.getValue() ? "Active" : "Inactive"}
      </Badge>
    ),
    header: "Status",
    footer: "Status",
  }),
];

function YourSubscription() {
  const { data } = useQuery<Query>(SUBSCRIPTION_USE_CASE_ACTIVE_SUBSCRIPTION);
  const { data: subscriptionHistory } = useQuery<Query>(
    SUBSCRIPTION_USE_CASE_SUBSCRIPTION_HISTORY
  );
  const [upgradeSubscription] = useMutation<Mutation>(
    SUBSCRIPTION_USE_CASE_UPGRADE_SUBSCRIPTION
  );
  const [cancelSubscription] = useMutation<
    Mutation,
    MutationSubscriptionUseCaseCancelSubscriptionArgs
  >(SUBSCRIPTION_USE_CASE_CANCEL_SUBSCRIPTION);
  const isPro = data?.subscriptionUseCaseActiveSubscription?.planId === "pro";

  const table = useReactTable({
    data: subscriptionHistory?.subscriptionUseCaseSubscriptionHistory ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const onUpgradePlan = async () => {
    const res = await upgradeSubscription();
    if (res?.data?.subscriptionUseCaseUpgradeSubscription?.sessionUrl) {
      window.location.href =
        res.data.subscriptionUseCaseUpgradeSubscription.sessionUrl;
      return;
    }
    toast.error("Failed to upgrade plan");
  };

  const onCancelPlan = async () => {
    const res = await cancelSubscription({
      variables: {
        subscriptionId: data?.subscriptionUseCaseActiveSubscription
          ?.id as string,
      },
    });
    if (res?.data?.subscriptionUseCaseCancelSubscription) {
      toast.success("Subscription cancelled");
      return;
    }
    toast.error("Failed to cancel subscription");
  };
  return (
    <>
      <Header>
        <HeaderLeft>
          <Breadcrumb path="Home / Subscription" currentPage="Subscription" />
        </HeaderLeft>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name="Subscription">
        <Container>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <h1 className="my-4 font-bold">Your subscription</h1>
            </div>
          </div>
          <div className="w-full grid grid-cols-12 gap-x-[32px] mb-4">
            <div className="col-span-9">
              <Card>
                <CardBody>
                  <div className="card-body lg:py-7.5">
                    <div className="flex flex-col items-stretch gap-5 lg:gap-7.5">
                      <div className="flex flex-wrap items-center gap-5 justify-between">
                        <div className="flex flex-col gap-1">
                          <div className="flex items-center gap-2.5">
                            <h2 className="text-2xl font-semibold text-gray-900">
                              Your current plan -{" "}
                              {isPro ? "Pro Plan" : "Free Plan"}
                            </h2>
                            <Badge variant="solid">Monthly</Badge>
                          </div>
                          <p className="text-2sm text-gray-700">
                            Essential Features for Startups and Individuals
                          </p>
                        </div>
                        <div className="flex gap-2.5">
                          {isPro && (
                            <Button
                              icon="HeroServer"
                              variant="outline"
                              isDisable={false}
                              onClick={() => onCancelPlan()}
                            >
                              Cancel Plan
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <h1 className="my-4 font-bold">Subscription history</h1>
                </div>
              </div>
              <Card>
                <CardBody>
                  <Table className="table-fixed max-md:min-w-[70rem]">
                    <THead>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <Th
                              key={header.id}
                              isColumnBorder={false}
                              className="text-left"
                            >
                              {header.isPlaceholder ? null : (
                                <div
                                  key={header.id}
                                  aria-hidden="true"
                                  {...{
                                    className: header.column.getCanSort()
                                      ? "cursor-pointer select-none flex items-center"
                                      : "",
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: (
                                      <Icon
                                        icon="HeroChevronUp"
                                        className="ltr:ml-1.5 rtl:mr-1.5"
                                      />
                                    ),
                                    desc: (
                                      <Icon
                                        icon="HeroChevronDown"
                                        className="ltr:ml-1.5 rtl:mr-1.5"
                                      />
                                    ),
                                  }[header.column.getIsSorted() as string] ??
                                    null}
                                </div>
                              )}
                            </Th>
                          ))}
                        </Tr>
                      ))}
                    </THead>
                    <TBody>
                      {table.getRowModel().rows.map((row) => (
                        <Tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <Td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          ))}
                        </Tr>
                      ))}
                    </TBody>
                  </Table>
                </CardBody>
              </Card>
            </div>
            {!isPro && (
              <div className="col-span-3">
                <Card>
                  <CardBody>
                    <div className="w-full max-w-sm p-4 sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                      <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">
                        Pro plan
                      </h5>
                      <div className="flex items-baseline text-gray-900 dark:text-white">
                        <span className="text-3xl font-semibold">$</span>
                        <span className="text-5xl font-extrabold tracking-tight">
                          20
                        </span>
                        <span className="ms-1 text-xl font-normal text-gray-500 dark:text-gray-400">
                          /month
                        </span>
                      </div>
                      <ul role="list" className="space-y-5 my-7">
                        <li className="flex items-center">
                          <svg
                            className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
                            2 team members
                          </span>
                        </li>
                        <li className="flex">
                          <svg
                            className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
                            20GB Cloud storage
                          </span>
                        </li>
                        <li className="flex">
                          <svg
                            className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
                            Integration help
                          </span>
                        </li>
                      </ul>
                      <Button
                        icon="HeroServer"
                        className="w-full"
                        variant="solid"
                        isDisable={false}
                        onClick={() => onUpgradePlan()}
                      >
                        Upgrade Now
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </div>
            )}
          </div>
        </Container>
      </PageWrapper>
    </>
  );
}

export default YourSubscription;
