import { gql } from "@apollo/client";

export const REGISTER_MUTATION = gql(/* GraphQL */ `
  mutation AuthSignUpWithEmailAndPassword($input: SignUpEmailPasswordInput!) {
    authSignUpWithEmailAndPassword(input: $input)
  }
`);

export const LOGIN_MUTATION = gql(/* GraphQL */ `
  mutation AuthLoginWithEmailAndPassword($input: LoginEmailPasswordInput!) {
    authLoginWithEmailAndPassword(input: $input) {
      accessToken
      refreshToken
      user {
        email
        id
        planId
        role
        name
        status
        permissions
        picture
      }
    }
  }
`);

export const RESEND_REGISTRATION_EMAIL_MUTATION = gql(/* GraphQL */ `
  mutation AuthResendRegistrationEmail($input: ResendRegistrationEmailInput!) {
    authResendRegistrationEmail(input: $input)
  }
`);

export const CONFIRM_EMAIL_MUTATION = gql(/* GraphQL */ `
  mutation AuthConfirmEmail($input: ConfirmEmailInput!) {
    authConfirmEmail(input: $input)
  }
`);

export const LOGIN_WITH_REFRESH_TOKEN = gql(/* GraphQL */ `
  mutation AuthLoginWithRefreshToken {
    authLoginWithRefreshToken {
      accessToken
      refreshToken
      user {
        email
        id
        planId
        role
        name
        status
        permissions
        picture
      }
    }
  }
`);

export const LOGIN_WITH_GOOGLE_MUTATION = gql(/* GraphQL */ `
  mutation AuthLoginWithGoogle($token: String!) {
    authLoginWithGoogle(token: $token) {
      accessToken
      refreshToken
      user {
        email
        id
        name
        permissions
        picture
        planId
        role
        status
      }
    }
  }
`);

export const ME = gql(/* GraphQL */ `
  query UserInfo {
    userInfo {
      email
      id
      planId
      role
      name
      status
      permissions
      picture
    }
  }
`);

export const MASTER_DATA_COUNTRIES = gql(/* GraphQL */ `
  query MasterDataCountries {
    masterDataCountries
  }
`);

export const MASTER_DATA_LANGUAGES = gql(/* GraphQL */ `
  query MasterDataLanguages {
    masterDataLanguages
  }
`);

export const CART_USE_CASE_CURRENT_USER_CART = gql(/* GraphQL */ `
  query CartUseCaseCurrentUserCart {
    cartUseCaseCurrentUserCart {
      createdAt
      customerId
      expiresAt
      id
      lastUpdated
      updatedAt
      cartLineItems {
        price
        productId
        quantity
      }
    }
  }
`);

export const BILLING_USE_CASE_BILLING_HISTORIES = gql(/* GraphQL */ `
  query BillingUseCaseBillingHistories($pagination: Pagination!) {
    billingUseCaseBillingHistories(pagination: $pagination) {
      amount
      currency
      customerId
      id
      planId
      subscriptionId
      transactionStatus
    }
  }
`);

export const BILLING_USE_CASE_BILLING_HISTORY = gql(/* GraphQL */ `
  query BillingUseCaseBillingHistory($id: String!) {
    billingUseCaseBillingHistory(id: $id) {
      _id
    }
  }
`);

export const ORDER_USE_CASE_ORDER_FOR_CURRENT_USER = gql(/* GraphQL */ `
  query OrderUseCaseOrderForCurrentUser {
    orderUseCaseOrderForCurrentUser {
      createdBy
      customerId
      id
      orderStatus
      paymentDate
      paymentStatus
      productIds
      totalAmount
      orderLineItems {
        price
        productId
        quantity
      }
    }
  }
`);

export const ORDER_USE_CASE_ORDER = gql(/* GraphQL */ `
  query OrderUseCaseOrder($id: String!) {
    orderUseCaseOrder(id: $id) {
      createdBy
      customerId
      id
      orderStatus
      paymentDate
      paymentStatus
      productIds
      totalAmount
      orderLineItems {
        price
        productId
        quantity
      }
    }
  }
`);

export const SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUPS = gql(/* GraphQL */ `
  query SocialMediaUseCaseFacebookGroups($pagination: Pagination!) {
    socialMediaUseCaseFacebookGroups(pagination: $pagination) {
      active
      coverImageUrl
      creationDate
      description
      groupId
      id
      isFeatured
      name
      thumbnailUrl
      type
      url
      admins {
        avatarUrl
        name
      }
      contentAnalysisId
      metadata
    }
  }
`);

export const SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUP = gql(/* GraphQL */ `
  query SocialMediaUseCaseFacebookGroup($id: String!) {
    socialMediaUseCaseFacebookGroup(id: $id) {
      active
      contentAnalysisId
      metadata
      coverImageUrl
      creationDate
      description
      groupId
      id
      isFeatured
      name
      thumbnailUrl
      type
      url
      admins {
        avatarUrl
        name
      }
    }
  }
`);

export const SOCIAL_MEDIA_USE_CASE_FEATURED_FACEBOOK_GROUP = gql(/* GraphQL */ `
  query SocialMediaUseCaseFeaturedFacebookGroup {
    socialMediaUseCaseFeaturedFacebookGroup {
      active
      contentAnalysisId
      metadata
      coverImageUrl
      creationDate
      description
      groupId
      id
      isFeatured
      name
      thumbnailUrl
      type
      url
      admins {
        avatarUrl
        name
      }
    }
  }
`);

export const SOCIAL_MEDIA_USE_CASE_RECENT_UPDATE_FACEBOOK_GROUP = gql(
  /* GraphQL */ `
    query SocialMediaUseCaseRecentUpdateFacebookGroup($id: String!) {
      socialMediaUseCaseRecentUpdateFacebookGroup(id: $id) {
        active
        contentAnalysisId
        metadata
        coverImageUrl
        creationDate
        description
        groupId
        id
        isFeatured
        name
        thumbnailUrl
        type
        url
        admins {
          avatarUrl
          name
        }
      }
    }
  `
);

export const ORDER_USE_CASE_ORDERS_FOR_CURRENT_USER = gql(/* GraphQL */ `
  query OrderUseCaseOrdersForCurrentUser {
    orderUseCaseOrdersForCurrentUser {
      createdBy
      customerId
      id
      orderStatus
      paymentDate
      paymentStatus
      productIds
      totalAmount
      orderLineItems {
        price
        productId
        quantity
      }
    }
  }
`);

export const CART_USE_CASE_ADD_TO_CART = gql(/* GraphQL */ `
  mutation CartUseCaseAddToCart($input: AddToCartInput!) {
    cartUseCaseAddToCart(input: $input) {
      createdAt
      customerId
      expiresAt
      id
      lastUpdated
      updatedAt
      cartLineItems {
        price
        productId
        quantity
      }
    }
  }
`);

export const CART_USE_CASE_REMOVE_FROM_CART = gql(/* GraphQL */ `
  mutation CartUseCaseRemoveFromCart($input: RemoveFromCartInput!) {
    cartUseCaseRemoveFromCart(input: $input) {
      createdAt
      customerId
      expiresAt
      id
      lastUpdated
      updatedAt
      cartLineItems {
        price
        productId
        quantity
      }
    }
  }
`);

export const CART_USE_CASE_CHECKOUT_CART = gql(/* GraphQL */ `
  mutation CartUseCaseCheckoutCart {
    cartUseCaseCheckoutCart {
      sessionUrl
    }
  }
`);

export const USER_EDIT_INFO = gql(/* GraphQL */ `
  mutation UserEditInfo($input: UserEditInfoInput!) {
    userEditInfo(input: $input) {
      email
      id
      planId
      role
      name
      status
      permissions
      picture
    }
  }
`);

export const SUBSCRIPTION_USE_CASE_ACTIVE_SUBSCRIPTION = gql(/* GraphQL */ `
  query SubscriptionUseCaseActiveSubscription {
    subscriptionUseCaseActiveSubscription {
      id
      customerId
      expiredDate
      isActive
      isCancelled
      isExpired
      planId
    }
  }
`);

export const SUBSCRIPTION_USE_CASE_UPGRADE_SUBSCRIPTION = gql(/* GraphQL */ `
  mutation SubscriptionUseCaseUpgradeSubscription {
    subscriptionUseCaseUpgradeSubscription(planId: "pro") {
      sessionUrl
    }
  }
`);

export const SUBSCRIPTION_USE_CASE_CANCEL_SUBSCRIPTION = gql(/* GraphQL */ `
  mutation SubscriptionUseCaseCancelSubscription($subscriptionId: String!) {
    subscriptionUseCaseCancelSubscription(subscriptionId: $subscriptionId)
  }
`);

export const SUBSCRIPTION_USE_CASE_SUBSCRIPTION_HISTORY = gql(/* GraphQL */ `
  query SubscriptionUseCaseSubscriptionHistory {
    subscriptionUseCaseSubscriptionHistory {
      _id
      customerId
      expiredDate
      isActive
      isCancelled
      isExpired
      planId
    }
  }
`);

export const UPLOAD_FILE_IDS = gql(/* GraphQL */ `
  mutation UploadFileIds($ids: [String!]!) {
    uploadFileIds(ids: $ids)
  }
`);

export const SOCIAL_MEDIA_USE_CASE_SAVE_SOCIAL_MEDIA = gql(/* GraphQL */ `
  mutation SocialMediaUseCaseSaveSocialMedia($referenceId: String!) {
    socialMediaUseCaseSaveSocialMedia(referenceId: $referenceId)
  }
`);

export const SOCIAL_MEDIA_USE_CASE_REMOVE_SOCIAL_MEDIA = gql(/* GraphQL */ `
  mutation SocialMediaUseCaseRemoveSocialMedia($id: String!) {
    socialMediaUseCaseRemoveSocialMedia(id: $id)
  }
`);

export const SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUP_POST = gql(/* GraphQL */ `
  query SocialMediaUseCaseFacebookGroupPost($id: String!) {
    socialMediaUseCaseFacebookGroupPost(id: $id) {
      _id
      createdAt
      facebookGroupId
      id
      isFeatured
      isPinned
      isSponsored
      message
      postId
      postUrl
      thumbnailUrl
      title
      updatedAt
    }
  }
`);

export const SOCIAL_MEDIA_USE_CASE_FEATURED_FACEBOOK_GROUP_POSTS = gql(
  /* GraphQL */ `
    query SocialMediaUseCaseFeaturedFacebookGroupPosts(
      $pagination: Pagination!
    ) {
      socialMediaUseCaseFeaturedFacebookGroupPosts(pagination: $pagination) {
        _id
        createdAt
        facebookGroupId
        id
        isFeatured
        isPinned
        isSponsored
        message
        postId
        postUrl
        thumbnailUrl
        title
        updatedAt
      }
    }
  `
);

export const SOCIAL_MEDIA_USE_CASE_RECENT_UPDATE_FACEBOOK_GROUP_POSTS = gql(
  /* GraphQL */ `
    query SocialMediaUseCaseRecentUpdateFacebookGroupPosts(
      $pagination: Pagination!
      $facebookGroupId: String!
    ) {
      socialMediaUseCaseRecentUpdateFacebookGroupPosts(
        pagination: $pagination
        facebookGroupId: $facebookGroupId
      ) {
        _id
        createdAt
        facebookGroupId
        id
        isFeatured
        isPinned
        isSponsored
        message
        postId
        postUrl
        thumbnailUrl
        title
        updatedAt
      }
    }
  `
);

export const THE_ALITA_REPORT = gql(/* GraphQL */ `
  mutation TheAlitaReport {
    theAlitaReport
  }
`);

export const REPORT_USE_CASE_REPORTS_BY_GROUP = gql(/* GraphQL */ `
  query SocialReportUseCaseReportsByGroup($facebookGroupId: String!) {
    socialReportUseCaseReportsByGroup(facebookGroupId: $facebookGroupId) {
      categories
      contentAnalysisId
      facebookGroupId
      id
      imageUrl
      name
      productIds
      reportDate
      socialMedia
      status
      thumbnailUrl
    }
  }
`);

export const REPORT_USE_CASE_LATEST_REPORT_BY_GROUP = gql(/* GraphQL */ `
  query ReportUseCaseLatestReportByGroup($groupId: String!) {
    reportUseCaseLatestReportByGroup(groupId: $groupId) {
      categories
      contentAnalysisId
      facebookGroupId
      id
      imageUrl
      name
      productIds
      reportDate
      socialMedia
      status
      thumbnailUrl
    }
  }
`);

export const REPORT_USE_CASE_REPORT = gql(/* GraphQL */ `
  query SocialReportUseCaseReport($id: String!) {
    socialReportUseCaseReport(id: $id) {
      categories
      contentAnalysisId
      facebookGroupId
      id
      imageUrl
      name
      productIds
      reportDate
      socialMedia
      status
      thumbnailUrl
    }
  }
`);

export const PRODUCT_USE_CASE_PRODUCT_BY_IDS = gql(/* GraphQL */ `
  query ProductUseCaseProductByIds($productIds: [String!]!) {
    productUseCaseProductByIds(productIds: $productIds) {
      category
      description
      fileId
      id
      name
      price
      kind
      memberAnalysisId
    }
  }
`);

export const PRODUCT_USE_CASE_PRODUCT = gql(/* GraphQL */ `
  query ProductUseCaseProduct($productId: String!) {
    productUseCaseProduct(productId: $productId) {
      category
      description
      fileId
      id
      kind
      name
      price
      memberAnalysisId
    }
  }
`);

export const PRODUCT_USE_CASE_PURCHASED_PRODUCTS = gql(/* GraphQL */ `
  query ProductUseCasePurchasedProducts {
    productUseCasePurchasedProducts {
      fileId
      id
      productId
      userId
    }
  }
`);

export const PRODUCT_USE_CASE_DOWNLOAD_FILE = gql(/* GraphQL */ `
  query ProductUseCaseDownloadFile($productId: String!) {
    productUseCaseDownloadPurchasedFile(productId: $productId) {
      downloadUrl
      isPurchased
    }
  }
`);

export const SOCIAL_REPORT_USE_CASE_CONTENT_ANALYSIS = gql(/* GraphQL */ `
  query SocialReportUseCaseContentAnalysis($id: String!) {
    socialReportUseCaseContentAnalysis(id: $id) {
      avgInteractions
      id
      isDeleted
      postAnalysis
      reportDate
      totalComments
      totalInteractions
      totalLikes
      totalShares
      facebookGroupId
      mostKeywords {
        key
        value
        percentage
      }
    }
  }
`);

export const SOCIAL_REPORT_USE_CASE_MEMBER_ANALYSIS = gql(/* GraphQL */ `
  query SocialReportUseCaseMemberAnalysis($id: String!) {
    socialReportUseCaseMemberAnalysis(id: $id) {
      id
      kind
      reportDate
      totalDob
      totalEmails
      totalFacebookNames
      totalFacebookUids
      totalFollowers
      totalFriends
      totalGenders
      totalLocations
      totalMembers
      totalPhones
      totalRelationships
      cityAnalysis {
        key
        value
      }
      followerAnalysis {
        key
        value
      }
      friendAnalysis {
        key
        value
      }
      genderAnalysis {
        key
        value
      }
      relationshipAnalysis {
        key
        value
      }
    }
  }
`);

export const SOCIAL_REPORT_USE_CASE_DOWNLOAD_SHARING_REPORT = gql(
  /* GraphQL */ `
    mutation SocialReportUseCaseDownloadSharingReport($id: String!) {
      socialReportUseCaseDownloadSharingReport(id: $id) {
        downloadUrl
        isPurchased
      }
    }
  `
);

export const SOCIAL_REPORT_USE_CASE_SHARING_REPORTS = gql(/* GraphQL */ `
  query SocialReportUseCaseSharingReports(
    $pagination: Pagination!
    $filter: SharingReportFilter!
  ) {
    socialReportUseCaseSharingReports(
      pagination: $pagination
      filter: $filter
    ) {
      categories
      description
      shortDescription
      fileId
      id
      imageUrl
      isDeleted
      name
      reportDate
      thumbnail
    }
  }
`);

export const SOCIAL_REPORT_USE_CASE_SHARING_CATEGORIES = gql(/* GraphQL */ `
  query SocialReportUseCaseSharingCategories {
    socialReportUseCaseSharingCategories
  }
`);

export const SOCIAL_REPORT_USE_CASE_CATEGORIES = gql(/* GraphQL */ `
  query SocialReportUseCaseCategories {
    socialReportUseCaseCategories
  }
`);

export const SOCIAL_REPORT_USE_CASE_REPORTS = gql(/* GraphQL */ `
  query SocialReportUseCaseReports(
    $pagination: Pagination!
    $filter: ReportFilter!
  ) {
    socialReportUseCaseReports(pagination: $pagination, filter: $filter) {
      categories
      contentAnalysisId
      facebookGroupId
      id
      imageUrl
      name
      productIds
      reportDate
      socialMedia
      status
      thumbnailUrl
    }
  }
`);

export const SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUP_POSTS = gql(/* GraphQL */ `
  query SocialMediaUseCaseFacebookGroupPosts($facebookGroupId: String!) {
    socialMediaUseCaseFacebookGroupPosts(facebookGroupId: $facebookGroupId) {
      authorId
      comments
      content
      id
      index
      likes
      shares
      totalInteraction
    }
  }
`);

export const PRODUCT_USE_CASE_SAMPLE_AUDIENCE = gql(/* GraphQL */ `
  query ProductUseCaseSampleAudience($fileId: String!) {
    productUseCaseSampleAudience(fileId: $fileId) {
      city
      dob
      email
      facebookName
      facebookUid
      follow
      friends
      gender
      location
      phone
      relationship
    }
  }
`);

export const PRIVACY_POLICY = gql(/* GraphQL */ `
  query PrivacyPolicy {
    privacyPolicy
  }
`);

export const TERM_AND_CONDITION = gql(/* GraphQL */ `
  query TermAndCondition {
    termAndCondition
  }
`);
