import ContentAnalytic from "@/components/group/ContentAnalytic";
import Breadcrumb from "@/components/layouts/Breadcrumb/Breadcrumb";
import Container from "@/components/layouts/Container/Container";
import Header, {
  HeaderLeft,
  HeaderRight,
} from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Button from "@/components/ui/Button";
import Card, {
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/Card";
import {
  Query,
  QuerySocialMediaUseCaseFacebookGroupArgs,
  QuerySocialReportUseCaseReportsByGroupArgs,
} from "@/gql/graphql";
import {
  REPORT_USE_CASE_REPORTS_BY_GROUP,
  SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUP,
} from "@/gql/schema";
import { useData } from "@/hooks/useData";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { useQuery } from "@apollo/client";
import {
  createFileRoute,
  redirect,
  useParams,
  useRouter,
} from "@tanstack/react-router";
import dayjs from "dayjs";

export const Route = createFileRoute("/facebook-groups/group/$id")({
  component: FacebookGroupDetail,
  beforeLoad: ({ context, location }) => {
    if (!context.user) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

function FacebookGroupDetail() {
  const router = useRouter();
  const id = useParams({ from: "/facebook-groups/group/$id" }).id;
  const { data } = useQuery<Query, QuerySocialMediaUseCaseFacebookGroupArgs>(
    SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUP,
    {
      variables: { id },
    }
  );
  const { data: reportData } = useData<
    Query,
    QuerySocialReportUseCaseReportsByGroupArgs
  >(REPORT_USE_CASE_REPORTS_BY_GROUP, {
    variables: {
      facebookGroupId: data?.socialMediaUseCaseFacebookGroup?.id ?? "",
    },
    skip: !data?.socialMediaUseCaseFacebookGroup?.id,
  });

  return (
    <>
      <Header>
        <HeaderLeft>
          <Breadcrumb
            path="Home / Facebook Groups"
            currentPage={data?.socialMediaUseCaseFacebookGroup?.name ?? ""}
          />
        </HeaderLeft>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name={data?.socialMediaUseCaseFacebookGroup?.name ?? ""}>
        <Container>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 flex flex-row items-center justify-between">
              <h1 className="text-[28px] my-4 font-bold">
                {data?.socialMediaUseCaseFacebookGroup?.name ?? ""}
              </h1>
              <Button
                onClick={() =>
                  window.open(
                    data?.socialMediaUseCaseFacebookGroup?.url ?? "",
                    "_blank"
                  )
                }
                variant="solid"
              >
                Facebook Group
              </Button>
            </div>
          </div>
          <div className="w-full grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-12">
              <Card>
                <CardBody>
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 lg:col-span-6">
                      <label
                        data-component-name="Label"
                        className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                        htmlFor="id"
                      >
                        Type
                      </label>
                      <p className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50 uppercase">
                        {data?.socialMediaUseCaseFacebookGroup?.type ?? ""}
                      </p>
                      <label
                        data-component-name="Label"
                        className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                        htmlFor="id"
                      >
                        Status
                      </label>
                      <p className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50">
                        {data?.socialMediaUseCaseFacebookGroup?.active ?? ""}
                      </p>
                      <label
                        data-component-name="Label"
                        className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                        htmlFor="id"
                      >
                        Description
                      </label>
                      <p className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50">
                        {data?.socialMediaUseCaseFacebookGroup?.description ??
                          ""}
                      </p>
                      <label
                        data-component-name="Label"
                        className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                        htmlFor="id"
                      >
                        Created Date
                      </label>
                      <p className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50">
                        {dayjs(
                          data?.socialMediaUseCaseFacebookGroup?.creationDate
                        ).format("DD MMM YYYY")}
                      </p>
                    </div>
                    <div className="col-span-12 lg:col-span-6 text-left">
                      <img
                        src={
                          data?.socialMediaUseCaseFacebookGroup?.coverImageUrl
                        }
                        className="w-full h-[180px] object-contain float-left"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <h1 className="text-[28px] my-4 font-bold">Reports</h1>
            </div>
          </div>
          <div className="w-full grid grid-cols-4">
            {reportData?.socialReportUseCaseReportsByGroup.map((report) => (
              <Card
                key={report.id}
                className="cursor-pointer hover:shadow-md"
                onClick={() => {
                  router.navigate({
                    to: "/facebook-groups/report/$id",
                    params: {
                      id: report.id,
                    },
                    search: {
                      group: data?.socialMediaUseCaseFacebookGroup?.id ?? "",
                    },
                  });
                }}
              >
                <CardHeader>
                  <CardTitle>{report.name}</CardTitle>
                </CardHeader>
                <CardBody>
                  <img
                    src={report.imageUrl}
                    className="w-full h-[150px] object-cover"
                  />
                </CardBody>
                <CardFooter>
                  Report date: {dayjs(report.reportDate).format("DD MMM YYYY")}
                  <Button variant="solid">View</Button>
                </CardFooter>
              </Card>
            ))}
          </div>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <h1 className="text-[28px] my-4 font-bold">Content Analytics</h1>
            </div>
            <div className="col-span-12">
              <ContentAnalytic
                id={
                  data?.socialMediaUseCaseFacebookGroup?.contentAnalysisId ?? ""
                }
              />
            </div>
          </div>
          {/* <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <h1 className="text-[28px] my-4 font-bold">Member Analytics</h1>
            </div>
          </div> */}
        </Container>
      </PageWrapper>
    </>
  );
}

export default FacebookGroupDetail;
