import {
  createRouter,
  RouterProvider,
  useRouter,
} from "@tanstack/react-router";
import { useAuthStore } from "./store/auth";
import { routeTree } from "./routeTree.gen";
import { Query, User } from "./gql/graphql";
import getOS from "./utils/getOS.util";
import "react-toastify/dist/ReactToastify.css";
import colors from "./config/color";
import { ToastContainer } from "react-toastify";
import useDarkMode from "./hooks/useDarkMode";
import useDir from "./hooks/useDir";
import themeConfig from "./config/theme.config";
import { useEffect } from "react";
import { ME } from "./gql/schema";
import { useQuery } from "@apollo/client";

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    // auth will initially be undefined
    // We'll be passing down the auth state from within a React component
    user: undefined!,
  },
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
    context: {
      user: User | null;
    };
  }
}

function InnerApp() {
  getOS();
  const { isDarkTheme } = useDarkMode();
  const { data: profile, loading } = useQuery<Query>(ME);
  const { user, setUser, setToken, setRefreshToken } = useAuthStore(
    (state) => state
  );
  const { isRTL } = useDir();
  useEffect(() => {
    if (loading) return;
    if (!profile) {
      const currentPath = router.state.location.pathname;
      const publicRoutes = [
        "/login",
        "/register",
        "/forgot-password",
        "/auth/confirm",
        "/payment/success",
        "/payment/cancel",
        "/privacy-policy",
        "/term-of-service",
      ];
      if (publicRoutes.includes(currentPath)) return;
      if (currentPath.includes("/reports/")) return;
      setUser(null);
      setToken(null);
      setRefreshToken(null);
      router.navigate({ to: "/login" });
    }
  }, [profile, loading]);
  return (
    <>
      <style>{`:root {font-size: ${themeConfig.fontSize}px;
			--toastify-toast-bd-radius: 0.75rem;
			--toastify-color-dark:  ${colors.zinc["800"]};
			--toastify-color-info: ${colors.blue["500"]};
			--toastify-color-success: ${colors.emerald["500"]};
			--toastify-color-warning: ${colors.amber["500"]};
			--toastify-color-error: ${colors.red["500"]};
			--toastify-color-progress-light: linear-gradient(
				to right,
				${colors.blue["500"]},
				${colors.emerald["500"]}, ${colors.amber["500"]}, ${colors.red["500"]});`}</style>
      <RouterProvider router={router} context={{ user: user! }} />
      <ToastContainer
        theme={isDarkTheme ? "dark" : "light"}
        hideProgressBar
        rtl={isRTL}
      />
    </>
  );
}

export default InnerApp;
